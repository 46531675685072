import React from 'react';
import './LetsChatContactForm.css';
import { Col, Button, FormGroup } from 'reactstrap';
import Input from "../global/Input";
import TextArea from "../global/TextArea";

class LetChatContactForm extends React.Component {

    constructor() {
        super();
        this.state = {

            newLead: {
                name: "",
                email: "",
                phone: "",
                message: "",
            },
            isFormSubmitted : false
        };
        this.handleTextArea = this.handleTextArea.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
      }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
          prevState => ({
            newLead: {
              ...prevState.newLead,
              [name]: value
            }
          }),
          () => console.log(this.state.newLead)
        );
      }
    
      handleTextArea(e) {
        console.log("Inside handleTextArea");
        let value = e.target.value;
        this.setState(
          prevState => ({
            newLead: {
              ...prevState.newLead,
              message: value
            }
          }),
          () => console.log(this.state.newLead)
        );
      }

    handleFormSubmit(e) {
        e.preventDefault();
        let userData = this.state.newLead;
        console.log(userData);
        console.log(JSON.stringify(userData));


        (async () => {
            const rawResponse = await fetch('https://tcmsapi.tabarja.com.au/newLead', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({name: userData.name, email: userData.email, phone: userData.phone, message: userData.message})
            });
            await rawResponse.json().then(data => {
                    console.log("Successful" + data);
                    this.setState({isFormSubmitted: true});
                });
                          
          })();
      }

    render() {
        const isFormSubmitted = this.state.isFormSubmitted;
        if (isFormSubmitted) {
            return (
                <div className="valor-sec sec-getintouch-form">
                    <Col className="text-center mb-5">
                        <h2 className="sec-header-sub">Thank You</h2>
                        <p>
                            One of our team members will be in touch
                                
                            </p>
                    </Col>
                </div>
            )
        } else {
            return (
                <div className="valor-sec valor-contact-form">
                    <Col className="text-center mb-5">
                        <h2 className="sec-header-sub">Contact Us</h2>
                        <p>
                            Drop us a message with a few details on what you need<br/>
                            We will have one of our specialists get in contact with you shortly<br/> We look forward to meeting you 
                        </p>
                    </Col>
                    <Col>
                        <form onSubmit={this.handleFormSubmit}>
                            <div className="d-md-flex justify-content-center">
                                <div className="col-12 col-md-3 form-left">
                                    <FormGroup>
                                        <Input
                                            inputType={"text"}
                                            title={"Name"}
                                            name={"name"}
                                            value={this.state.newLead.name}
                                            placeholder={""}
                                            handleChange={this.handleInput}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            inputType={"text"}
                                            title={"Email"}
                                            name={"email"}
                                            value={this.state.newLead.email}
                                            placeholder={""}
                                            handleChange={this.handleInput}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            inputType={"phone"}
                                            title={"Phone Number"}
                                            name={"phone"}
                                            value={this.state.newLead.phone}
                                            placeholder={""}
                                            handleChange={this.handleInput}
                                        />
                                    </FormGroup>
                                </div>
                                <Col md="3" className="form-right">
                                    <FormGroup>
                                        <TextArea
                                            title={"Message"}
                                            rows={10}
                                            value={this.state.newLead.message}
                                            name={"message"}
                                            handleChange={this.handleTextArea}
                                            placeholder={""}
                                        />
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="col-12 text-center">
                                    <Button type="submit" className="btn valor-btn-submit valor-btn-border">Submit</Button>
                                </div>
                            </div>
                        </form>
                    </Col>
                </div>
            );
        }
    }
}

export default LetChatContactForm