import React from 'react';
import './LetsChatValorDetails.css';
import linkedInLogoYellow from '../../assets/contact/valor-digital-solutions-contact-linkedin.svg';
import facebookLogoYellow from '../../assets/contact/valor-digital-solutions-contact-facebook.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class LetChatValorDetails extends React.Component {

    render() {
        return (
            <div className="row justify-content-center valor-sec pb-5">
                <div className="col-lg-3 col-md-4 ml-lg-5 text-md-left text-center mb-4" >
                    <h2 className="sec-header-sub">Head office</h2>
                    <p className="mt-3">
                        500A King St<br />
                        Newtown, Sydney<br />
                        NSW 2024
                    </p>
                </div>
                <div className="col-lg-3 col-md-4 text-md-left text-center mb-4" >
                    <h2 className="sec-header-sub">Contact us</h2>
                    <p className="mb-0 mt-3 d-md-flex ml-3p"><FontAwesomeIcon icon="phone" rotation={90} /><span className="pl-3">02 8007 3277</span></p>
                    <p className="d-md-flex email-text ml-3p"><FontAwesomeIcon icon="envelope" /><span className="pl-3">info@ValorDigital.com.au</span></p>
                </div>
                <div className="col-lg-3 col-md-4 text-md-left text-center mb-4" >
                    <h2 className="sec-header-sub">Connect with us</h2>
                    <div className="mt-3 ml-3p">
                        <a href="https://linkedin.com/company/valordigital" className="mr-2"><img src={linkedInLogoYellow} height="42" alt="Valor Digital Solutions" /></a>
                        <a href="https://www.facebook.com/valordigitalsolutions"><img src={facebookLogoYellow} height="42" alt="Valor Digital Solutions" /></a>
                    </div>
                </div>
            </div>
        );
    }

}

export default LetChatValorDetails