import React from "react";
import LetsChatContactForm from "../components/contact/LetsChatContactForm";
import LetsChatValorDetails from "../components/contact/LetsChatValorDetails";
import GoogleMap from "../components/contact/GoogleMap";
import GetInTouchToday from "../components/global/GetInTouchToday";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";

const contact = () => (
  <Layout>
    <SEO
      title="Contact Us"
      keywords={[
        `valor digital solutions`,
        `Sydney`,
        `call us`,
        "email us",
        "contact number",
        "email address",
        "location"
      ]}
      description="Get in touch for a free consultation and learn more about what we offer"
    />
    <LetsChatContactForm />
    <LetsChatValorDetails />
    <GoogleMap />
    <GetInTouchToday />
  </Layout>
);

export default contact;
