import React from "react";
import "./GoogleMap.css";

class GoogleMap extends React.Component {
  render() {
    return (
      <div>
        <section>
          <div id="googleMap" className="valor-map">
            <div className="temp-map" />
          </div>
        </section>
      </div>
    );
  }
}

export default GoogleMap;
